@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Nunito', sans-serif;
}

main {
  padding: 1rem;
  min-height: fit-content;
}

article {
  font-size: 1.2rem;
  width: 100%;
  margin: 1rem;
}

button {
  /* margin: 1rem 1rem 0.5rem; */
  font: inherit;
  font-size: 2rem;
  /* padding: 0.5em 1.5em; */
  cursor: pointer;
  border-radius: 0.5rem;

}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
